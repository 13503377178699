<template>
    <div
        class="admin-discount-button"
        :class="{ bchollos: $config.public.variant === 'bchollos', grayscale: btnDisabled }"
    >
        <button
            type="button"
            class="admin-discount-button__copy"
            :class="{ 'no-code': !discount.code, bchollos: $config.public.variant === 'bchollos' }"
            @click="() => copyCode(showCodeOnly)"
        >
            <p v-if="!!discount.code">
                {{
                    copied
                        ? 'Código copiado'
                        : recopied === 3
                          ? 'Copiar código y ver oferta'
                          : 'Copiar código'
                }}
            </p>
            <p
                :style="{
                    fontSize:
                        percentSize >= 10
                            ? percentSize < 15
                                ? '21px'
                                : percentSize < 17
                                  ? '19px'
                                  : '16px'
                            : undefined,
                }"
                :class="{
                    'letter-sizing': percentSize < 10,
                }"
            >
                {{ codeText }}
            </p>
        </button>
    </div>
</template>
<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '../../types/models'

export default defineComponent({
    props: {
        discount: {
            type: Object as PropType<Models.Discount>,
            required: true,
        },
        store: {
            type: Object as PropType<Models.Store>,
        },
        btnDisabled: {
            type: Boolean,
        },
        openCoupon: {
            type: Function,
            default: (newPageOnly: boolean) => {
                return null
            },
        },
    },
    data() {
        const copyText = useClipboard()
        return {
            copyText,
            copied: this.$route.query?.slug === this.discount.slug ? true : null,
            recopied: 0,
            showCodeOnly: true /* full version to false */,
            copying: false,
            textAnimated: '',
            works: null as null | boolean,
        }
    },
    mounted() {
        if (this.isCurrentOpenCoupon) {
            setTimeout(() => {
                this.copied = false
            }, 5000)
        }
    },
    computed: {
        isCurrentOpenCoupon(): boolean {
            return this.$route.query.slug === this.discount.slug
        },
        showCode(): boolean {
            return this.$route.query.slug !== this.discount.slug
        },
        codeText(): string {
            if (this.discount.code && this.discount.code.trim().length) {
                const length = this.discount.code.length
                return this.$route.query.slug
                    ? this.discount.code
                    : this.discount.code?.slice(0, length >= 10 ? 7 : length - 3) + '...'
            }
            return this.$lang.components.btnDiscount.no_coupon_required
        },
        percentSize(): number {
            return this.discount.code?.length || 0
        },
    },
    methods: {
        async copyCode(clearAfter: boolean) {
            if (!this.discount.code || !this.discount.code.trim().length) {
                console.log('here')

                this.openCoupon(true)
                return
            }

            this.recopied < 3 && (this.recopied = this.recopied + 1)

            if (!this.$route.query.slug || this.recopied === 3) {
                if (this.discount.code && this.discount.code.trim().length) {
                    await this.copyText.copy(this.discount.code)
                }
                this.openCoupon(this.recopied === 3)
                return
            }

            if (this.copying) return

            if (this.discount.code && this.discount.code.trim().length) {
                this.copying = true

                await this.copyText.copy(this.discount.code)

                this.copied = true

                setTimeout(() => {
                    this.copying = false
                }, 3000)

                if (clearAfter) {
                    setTimeout(() => {
                        this.copied = false
                    }, 3000)
                }
            }
        },
    },
})
</script>

<style lang="postcss" scoped>
.admin-discount-button {
    @apply relative h-14 w-full rounded-[8px] shadow-[-3px_3px_theme('colors.site.primary.DEFAULT')] lg:h-16;

    &.bchollos {
        @apply shadow-[-3px_3px_theme('colors.site.primary.dark')];
    }
    > * {
        @apply h-full w-full rounded-[8px] px-2;
    }

    &__copy {
        @apply relative border-2 border-site-primary-hover bg-site-primary-hover-light;
        &.bchollos {
            @apply bg-site-primary;
        }
        p {
            @apply line-clamp-1 text-center;
            &:first-child {
                @apply flex-none text-xs text-white;
            }
            &:last-child {
                @apply line-clamp-2 flex-none break-all font-semibold text-white;
            }
            &.letter-sizing {
                @apply text-xl lg:text-2xl;
            }
        }
        &.no-code {
            p {
                &:last-child {
                    @apply text-base;
                }
            }
        }
    }
    &__quest-one {
        @apply flex flex-col items-center justify-center space-y-1 rounded-md border-2 border-site-primary;

        &-text {
            @apply mb-0.5 text-center text-xs leading-3;
        }
        &-buttons {
            @apply flex justify-center space-x-5;
            button {
                @apply flex flex-none rounded border-2 py-[1px] pl-0.5 pr-1.5 text-xs font-medium;

                :global(svg) {
                    @apply block h-4 w-4;
                }
                span {
                    @apply ml-1 block;
                }
                &:first-child {
                    @apply border-red-500 bg-red-500 bg-opacity-10 text-red-500;
                }
                &:last-child {
                    @apply border-green-500 bg-green-500 bg-opacity-10 text-green-500;
                }
            }
        }
    }
    &__quest-three {
        @apply flex flex-col items-center justify-center rounded-md border-2 border-site-primary;
        p {
            &:first-child {
                @apply text-center text-xl font-semibold leading-5;
            }
            &:last-child {
                @apply text-center text-xs;
            }
        }
    }

    &:hover {
        > .admin-discount-button__copy {
            @apply lg:left-[-3px] lg:top-[3px];
        }
    }

    &--modal-width {
        @apply w-[237px];
    }

    &__tooltip {
        @apply absolute bottom-[110%] left-1/2 h-auto w-auto -translate-x-1/2 whitespace-nowrap rounded-full bg-site-primary px-6 py-1 text-xs font-medium text-white;
    }
}
</style>
