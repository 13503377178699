<template>
    <article
        ref="$el"
        class="card-main-coupon"
        :class="$route.query.slug === info.slug ? 'animated-border' : ''"
    >
        <div class="card-main-coupon__content">
            <picture v-if="showBrandLogo && info.store && info.store.image" class="card-main-coupon__image">
                <client-only><img :src="info.store.image.url" /></client-only>
            </picture>

            <div v-else class="card-main-coupon__image" role="button" @click="() => openCoupon()">
                <span v-if="showNewPrice" class="number">{{ newCouponPrice }}</span>
                <span
                    class="type"
                    :class="!showNewPrice ? 'text-2xl sm:text-3xl' : 'bg-site-primary bg-opacity-10 text-sm'"
                    >{{ $lang.components.cardMainCoupons.coupon }}</span
                >
            </div>

            <div class="card-main-coupon__top">
                <div class="inner-left">
                    <div v-if="info.is_verified && !info.is_exclusive" class="hidden md:flex">
                        <img
                            :src="$assets.green.verified"
                            class="icon"
                            alt="icono verificado"
                            width="6px"
                            height="6px"
                        />
                        <span>{{ $lang.components.cardMainCoupons.verified }}</span>
                    </div>
                    <div v-if="info.is_exclusive" class="flex gap-0.5">
                        <img
                            :src="$assets.orange.exclusive"
                            class="icon"
                            alt="icono exclusivo"
                            width="6px"
                            height="6px"
                        />
                        <span>{{ $lang.components.cardMainCoupons.exclusive }}</span>
                    </div>
                </div>

                <div class="inner-right">
                    <client-only>
                        <div v-if="endInLessThanTwoDays" class="desktop">
                            <img
                                :src="$assets.red.timeleftsvg"
                                class="icon"
                                alt="Icono tiempo restante"
                                width="12px"
                                height="12px"
                            />
                            <p class="offer-time-text font-semibold text-red-500">
                                {{
                                    endToday
                                        ? $lang.components.cardMainCoupons.today
                                        : $lang.components.cardMainCoupons.tomorrow
                                }}
                            </p>
                        </div>
                    </client-only>

                    <div class="desktop">
                        <img
                            :src="
                                refreshedAt30MinsAgo
                                    ? $assets.gray.fire
                                    : updatedAt && !areTimestampsEqual
                                      ? $assets.gray.refreshedAt
                                      : $assets.gray.clock
                            "
                            class="flex-none"
                            alt="icono actualizado"
                            width="12px"
                            height="12px"
                        />
                        <template v-if="updatedAt && !areTimestampsEqual">
                            <p class="desktop">
                                {{ $lang.components.cardMainCoupons.updated_at }}
                                {{ timeAgo }}
                            </p>
                            <p class="mobile">
                                {{ $lang.components.cardMainCoupons.time_ago }}
                                {{ timeAgo }}
                            </p>
                        </template>
                        <p v-else>Hace {{ timeAgo }}</p>
                    </div>

                    <div v-if="endingDate.length && !endInLessThanTwoDays" class="desktop">
                        <img
                            :src="$assets.gray.offerFinishIn"
                            class="icon"
                            alt="Icono finaliza"
                            width="12px"
                            height="12px"
                        />
                        <p class="start-end-text">
                            {{ $lang.components.cardMainCoupons.end_at }}
                            {{ endingDate }}
                        </p>
                    </div>

                    <div v-if="info.is_local" class="desktop">
                        <span class="block h-3 w-3">
                            <img
                                :src="$assets.gray.local"
                                alt="icono gps"
                                class="block h-full w-full object-contain"
                            />
                        </span>
                        <p class="offer-time-text">{{ $lang.components.cardMainCoupons.local }}</p>
                    </div>

                    <div class="mobile">
                        <p>{{ $lang.components.cardMainCoupons.time_ago }} {{ timeAgo }}</p>
                    </div>
                </div>
            </div>

            <div class="card-main-coupon__title" role="button" @click="() => openCoupon()">
                <p>{{ info.title }}</p>
            </div>

            <div class="card-main-coupon__bottom">
                <p v-if="endingDate !== ''">
                    <img :src="$assets.gray.readtime" alt="icon" class="h-3" /> Termina el {{ endingDate }}
                </p>
                <p v-if="lastUse">
                    <img :src="$assets.gray.fire" alt="icon" /> Último uso hace {{ lastUse }}
                </p>
            </div>

            <ButtonAdminCoupon
                class="card-main-coupon__button"
                :discount="info"
                :store="storeData"
                :btn-disabled="isExpired"
                :open-coupon="openCoupon"
            />

            <button
                v-if="info.description && info.description.length"
                class="card-main-coupon__button-desc"
                type="button"
                @click="showDescription = !showDescription"
            >
                <span>{{
                    showDescription
                        ? $lang.components.cardMainCoupons.hide
                        : $lang.components.cardMainCoupons.show
                }}</span>
                <img :src="$assets.primary.arrowDown" alt="icon" :class="{ 'rotate-180': showDescription }" />
            </button>
        </div>
        <transition name="slide-down">
            <div v-if="showDescription" class="card-main-coupon__description">
                <p class="start-at"><span>Valido desde el: </span><time>11/02/2024</time></p>

                <div>
                    <p class="conditions-title">Condiciones:</p>
                    <div class="conditions-text" v-html="info.description"></div>
                </div>
            </div>
        </transition>
    </article>
</template>

<script lang="ts" setup>
// @ts-ignore
import { formatInTimeZone } from 'date-fns-tz'
import {
    differenceInMinutes,
    formatDistanceToNowStrict,
    isBefore,
    isToday,
    isTomorrow,
    parseISO,
} from 'date-fns'
import { es as locale } from 'date-fns/locale'
import { useAuthStore } from '~/store/auth'
import type { Models } from '~/types/models'
import { useDateFunctions } from '~/composables/DateFunctions'
import type { PropType } from 'vue'

const props = defineProps({
    info: {
        type: Object as PropType<Models.Discount>,
        required: true,
    },
    landing: {
        type: String,
    },
    storeInfo: {
        type: Object as PropType<Models.Store>,
        required: false,
    },
    showBrandLogo: {
        type: Boolean,
    },
})

const storeData = (props.info.store || props.storeInfo) as Models.Store

const { getDateDifference } = useDateFunctions()

const { $openers, $lang } = useNuxtApp()

const showDescription = ref(false)

const isOffer = computed(() => props.info.type === 'offer')
const isCoupon = computed(() => props.info.type === 'coupon')

const isExpired = computed(() => {
    return props.info.expired_at ? isBefore(parseISO(props.info.expired_at), new Date()) : false
})

const openCoupon = (newPageOnly = false) => {
    const slug = Route.params.slug
    if (!newPageOnly)
        $openers.openCoupon(
            props.info.slug,
            (slug as string) || props.info.store?.slug || props.storeInfo?.slug,
            props.landing,
        )
    else $openers.openCoupon(props.info.slug)
}

const endToday = computed(() => {
    return !!props.info.expired_at && isToday(parseISO(props.info.expired_at))
})
const endTomorrow = computed(() => {
    return !!props.info.expired_at && isTomorrow(parseISO(props.info.expired_at))
})
const endInLessThanTwoDays = computed(() => {
    return endToday.value || endTomorrow.value
})
const refreshedAt30MinsAgo = computed(() => {
    const date = props.info.updated_at && parseISO(props.info.updated_at)
    const difference = !!date && differenceInMinutes(new Date(), date)

    return (difference && difference > 0 && difference < 30 && difference) || 0
})
const createdAt = computed(() => {
    return parseISO(props.info.created_at)
})
const updatedAt = computed(() => {
    return props.info.updated_at ? parseISO(props.info.updated_at) : null
})
const areTimestampsEqual = computed(() => {
    return (
        !!createdAt.value &&
        !!updatedAt.value &&
        getDateDifference(createdAt.value) === getDateDifference(updatedAt.value)
    )
})
const lastUse = computed(() => {
    {
        return props.info.last_use
            ? formatDistanceToNowStrict(parseISO(props.info.last_use), {
                  locale,
              })
            : ''
    }
})
const timeAgo = computed(() => {
    return getDateDifference(updatedAt.value || '')
})

const endingDate = computed(() => {
    const date = props.info.expired_at && parseISO(props.info.expired_at)

    return date ? formatInTimeZone(date, $lang.timeZone, 'dd/MM/yyyy') : ''
})
const showNewPrice = computed(() => {
    return (
        (isOffer.value && !!props.info.new_price) ||
        (isCoupon.value &&
            (((props.info.discount_type === 'precio' || props.info.discount_type === 'porcentaje') &&
                props.info.discount_value !== '0') ||
                props.info.discount_type === 'envío gratis'))
    )
})
const newCouponPrice = computed(() => {
    return props.info.discount_type === 'envío gratis'
        ? $lang.components.cardMainGlobal.free_ship
        : props.info.discount_type === 'precio'
          ? $lang.symbol + props.info.discount_value + ' OFF'
          : props.info.discount_value === '100'
            ? $lang.components.cardMainGlobal.free
            : props.info.discount_value + '% OFF'
})

const Route = useRoute()

const $el = ref() as Ref<null | HTMLDivElement>

onMounted(() => {
    nextTick(() => {
        if (props.info.slug === Route.query.slug && $el.value) {
            document.documentElement.style.scrollBehavior = 'smooth'
            document.documentElement.scrollTop = $el.value?.getBoundingClientRect().top - 12
            document.documentElement.style.scrollBehavior = 'auto'
        }
    })
})
</script>

<style lang="postcss" scoped>
.card-main-coupon {
    @apply relative rounded-xl border border-gray-200 bg-white;
    &.animated-border {
        &::after {
            @apply absolute left-1/2 top-1/2 z-0 h-full w-full -translate-x-1/2 -translate-y-1/2 transform rounded-xl border border-site-primary;
            content: '';
            animation-name: animated-border;
            animation-duration: 1000ms;
            animation-iteration-count: 5;
            animation-fill-mode: forwards;
        }
    }
    &__content {
        @apply relative z-10 grid grid-cols-12 gap-x-2 p-2;
    }
    &__top {
        @apply col-span-8 col-start-5 row-span-1 mb-2 flex items-center justify-between text-xs text-gray-800 md:col-span-10 md:col-start-3 lg:text-sm;

        .inner-left {
            @apply lg:flex lg:items-start lg:space-x-4;
            div {
                @apply space-x-1;
                .icon {
                    @apply w-3;
                }
            }
        }

        .inner-right {
            @apply flex flex-wrap justify-end xl:gap-x-2;
            div {
                @apply flex flex-none items-center space-x-1;
                p {
                    @apply whitespace-nowrap text-xs;
                    span {
                        @apply whitespace-nowrap;
                    }
                }
                img {
                    @apply h-3 w-3 flex-none;
                }
            }
            .desktop {
                @apply hidden xl:flex;
            }
            .mobile {
                @apply xl:hidden;
            }
        }
    }

    &__image {
        @apply col-span-4 row-span-2 row-start-1 mx-auto flex h-full w-full flex-col items-center justify-center space-y-2 p-2 text-center text-site-primary md:col-span-2 md:row-span-3;

        .number {
            @apply text-2xl font-semibold leading-8 sm:text-3xl sm:leading-9;
        }

        .type {
            @apply px-1 py-0.5 font-semibold;
        }
    }

    &__title {
        @apply col-span-8 col-start-5 row-span-1 mb-2 block md:col-span-6 md:col-start-3 md:flex md:flex-grow md:space-x-2 lg:col-span-7;
        p {
            @apply line-clamp-3 gap-2 font-semibold leading-5 text-gray-800 xs:line-clamp-2 md:text-lg md:leading-6 lg:hover:underline;
        }
    }

    &__bottom {
        @apply col-span-12 col-start-1 row-span-1 mb-2 mt-2 flex justify-between md:col-span-6 md:col-start-3 md:justify-start md:space-x-5;

        p {
            @apply line-clamp-1 text-xs font-semibold text-gray-600;
            img {
                @apply inline-block w-3 align-sub;
            }
        }
    }

    &__button-desc {
        @apply col-span-12 flex max-w-max flex-none transform items-center space-x-1 justify-self-end text-xs transition-transform md:col-span-4;
        &.rotate-180 {
            @apply text-site-primary;
        }
        img {
            @apply w-3 flex-none;
        }
    }
    &__button {
        @apply col-span-12 mb-2 md:col-span-4 md:col-start-9 md:row-start-2 md:justify-self-end lg:col-span-3 lg:col-start-10;
    }
    &__description {
        @apply relative z-10 border-t p-2;

        .start-at {
            span {
                @apply font-medium;
            }
        }
        .conditions-title {
            @apply inline font-medium;
        }
        .conditions-text {
            @apply inline;
            > * {
                @apply inline;
            }
        }
    }
}
@keyframes animated-border {
    from {
        width: 100%;
        height: 100%;
        opacity: 0.8;
    }
    to {
        opacity: 0;
        width: calc(100% + 18px);
        height: calc(100% + 18px);
    }
}
</style>
